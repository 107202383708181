<template>
  <div class="node__block" @dblclick="openModal(itemData)">
    <div class="node__header">
      <div class="node__image" :class="[itemData.min == 0 && itemData.max == 0 ? 'node__imageblue' : itemData.min == itemData.max ? 'node__imagegreen' : (itemData.min > itemData.max ? 'node__imageorange' : 'node__imagered'), mode ? 'node__imageday' : 'node__imagenight']"><i class="fa-solid fa-building"></i></div>
    </div>
    <div class="node__bottom" :class="[itemData.min == 0 && itemData.max == 0 ? 'node__blue' : itemData.min == itemData.max ? 'node__green' : (itemData.min > itemData.max ? 'node__orange' : 'node__red'), mode ? 'node__bottomday' : 'node__bottomnight']">

      <div class="node__label"></div>
      <div class="node__info">
        <span class="node__title">{{itemData.title}}</span>
        <div>
          <div :class="itemData.min == 0 && itemData.max == 0 ? 'span__colorblue' : itemData.min == itemData.max ? 'span__colorgreen': (itemData.min > itemData.max ? 'span__colororange': 'span__colorred') ">
            <span>{{ itemData.min }}/{{ itemData.max }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "grid-node",

  props: {
    itemData: {
      type: Object,
    },
  },
  methods: {
    collapseData(e) {
      this.$emit("collapseData", e)
    },
    openModal(item) {
      const info = {
        key: item.key,
        id: item.open,
        title:item.title,
        staff:item.min
      }
      this.$emit("drawerInfo", info)
    }
  },
  computed: {
    ...mapGetters({
      mode: "MODE",
    })
  },
  created() {

  }
};
</script>

<style scoped>
  .node__block {
    width: 220px;
    height: 135px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 12px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: relative;
  }
  .node__header {
    z-index: 9;
  }
  .node__image {
    width: 50px;
    height: 50px;
    border: 4px solid #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    background: #fff;
    border-radius: 50%;
    margin-bottom: -25px;
  }
  .node__imageblue {
    color: #0096FF;
  }
  .node__imagegray {
    color: #73777B;
  }
  .node__imagered {
    color: #c73f3f;
  }
  .node__imageorange {
    color: #e6872e;
  }
  .node__imagegreen {
    color: #019267;
  }
  .node__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5px;
    z-index: 8;
    height: inherit;
    background: #fff;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
  .node__title {
    font-size: 13px;
  }
  .node__info {
    text-align: center;
  }
  .span__colorgreen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.span__colorred {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.span__colorgray {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.span__colororange {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.span__colorblue {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.span__colorgreen span {
  color: #019267;
  background-color: #e4edc5;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 10px;
  user-select: none;
}
.span__colorred span {
  color: #c73f3f;
  background-color: #e4edc5;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 10px;
  user-select: none;
}
.span__colorgray span {
  color: #73777B;
  background-color: #d3d3d3;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 10px;
  user-select: none;
}
.span__colororange span {
  color: #f19335;
  background-color: #d3d3d3;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 10px;
  user-select: none;
}
.span__colorblue span {
  color: #0096FF;
  background-color: #ddf8fc;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 10px;
  user-select: none;
}
.node__blue {
  border-top: 25px solid #0096FF;
}
.node__gray {
  border-top: 25px solid #73777B;
}
.node__green {
  border-top: 25px solid #019267;
}
.node__red {
  border-top: 25px solid #c73f3f;
}
.node__orange {
  border-top: 25px solid #e58a1b;
}
</style>
