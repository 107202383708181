
import Node from '../components/grid-node.vue';
import firstNode from '../components/firstNode.vue';


const mockData = {
  nodes: {
    isRoot: true,
    id: 'Root',
    title: 'СТРУКТУРА ОРГАНИЗАЦИИ',
    render: firstNode,
    endpoints: [{
      id: '1',
      orientation: [0, 1],
      pos: [0.5, 0]
    }],
    children: []
  },
  edges: []
};

export default mockData;

