<template>
  <div>
    <div class="app-modal__in">
      <div
          class="app-modal__header my-top-khan-title" style="padding:10px"
      >
          <p class="large--title m-0" style="font-size:20px; font-weight:bold" >{{ child.title }}: {{child.staff}}</p>
          <!-- <export-excel
              v-if="excel_list.length"
              class="btn excel_btn"
              :data="excel_list"
              worksheet="My Worksheet"
              name="users.xls"
              :fields="excel_fields"
            >
              <el-button size="mini">
                <i class="el-icon-document-delete"></i>  {{$t("message.excel")}}
              </el-button>
            </export-excel> -->
          <el-button @click="closeModal()" type="warning" plain> {{$t("message.close")}}</el-button>
      </div>
  </div>
  <div class="p-3 pt-0">
    <div class="itme-modal-left" v-loading="loadStaff">
      <div class="compane-mane">{{$t('message.staff')}}</div>
      <div>
        <div
          class="info-user-left"
          v-for="(staff, index) in staff_list"
          :key="'staff-' + index"
          @click="pushToProfile(staff)"
        >
          <div class="img">
            <img
              :src="
                staff.image ? baseUrl + staff.image.path : 'img/avatar.jpeg'
              "
              alt=""
            />
          </div>
          <div class="name-info-user-left">
            <div class="name_u">{{ staff.name + " " + staff.last_name }}</div>
            <div class="position_u" v-if="staff.position">
              {{ staff.position.name }}
            </div>
            <!-- <div class="salary">
              <div>
                <h4>Зарплата</h4>
              </div>
              <div>
                <ul class="salary-list">
                  <li v-for="(value, index) in staff.salary_amounts" :key="value">{{value}} {{index}}</li>
                </ul>
              </div>
            </div> -->
          </div>
        </div>        
      </div>
    </div>
  </div>
  </div>
 
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loadStaff: false,
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
    };
  },
  computed: {
    ...mapGetters({
      staff_list: "structure/staff_list",
    }),
  },
  props: {
    child: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      getCompanyStaffList: "structure/getCompanyStaffList",
      getBranchStaffList: "structure/getBranchStaffList",
      getDepartmentStaffList: "structure/getDepartmentStaffList",
    }),
    opened(child) {
      if (!this.loadStaff && child.key == "companies") {
        this.loadStaff = true;
        this.getCompanyStaffList(child.id)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.$alert(err);
            this.loadStaff = false;
          });
      }
      if (!this.loadStaff && child.key == "branches") {
        this.loadStaff = true;
        this.getBranchStaffList(child.id)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }
      if (!this.loadStaff && child.key == "departments") {
        this.loadStaff = true;
        console.log('this', this.child)
        console.log('child', child)
        this.getDepartmentStaffList(child.id)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }
    },
    closeModal() {
      this.$emit("closeOpenDrawer", false);
    },
    pushToProfile(staff) {
      this.$router.push({
        name: "profile",
        params: { id: staff.id },
      });
    },
  },
};
</script>


<style lang="scss">
.compane-mane {
  border-bottom: 1px solid #d0d0d0;
  font-size: 18px;
  padding-bottom: 4px;
  font-weight: 700;
  margin-top: 15px;
}
.salary{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}
.salary h4{
  margin: 0;
  font-size: 11px;
}
.salary-list{
  font-size: 10px;
}
.info-user-left img {
  width: 45px;
  height: 45px;
  border: 1px solid #d0d0d0;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #f5f5f5;
   -o-object-fit: cover;
      object-fit: cover;
      overflow: hidden;
}
.info-user-left {
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #22292f20;
  border-radius: 0.428rem;
  box-shadow: 0 4px 24px 0 #22292f03;
  width: 100%;
  /* margin: 0px 10px; */
  cursor: pointer;
  padding: 10px;
}
.name_u {
  font-weight: bold;
  font-size: 15px;
}
.position_u {
  color: #8a8a8a;
  font-size: 12px;
  font-weight: 300;
  margin-top: 2px;
}

</style>
